import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { BehaviorSubject, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class UserService {
  baseUrl: string = `${environment.baseURL}` + "user";
  dictionaryBaseUrl: string = `${environment.baseURL}` + "dictionary";

  public profileImage = new BehaviorSubject<any[]>([]);

  errorData: {};

  constructor(private http: HttpClient) {}

  /**
   * It will get all user list
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  getAllUser() {
    return this.http.get(this.baseUrl + "/list");
  }

  getGenderList() {
    return this.http.get(this.dictionaryBaseUrl + "/get?dictionary_type=gender_masters");
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get record by filter
   * @param pageNo
   * @param sortField
   * @param sortOrder
   * @param globalField
   */
  getRecordByFilter(pageNo, sortField, sortOrder, globalFilter, rows) {
    let sortFieldName = "";
    let page = "";
    let globalSearch = "";
    let perPage = "";

    if (pageNo !== NaN && pageNo != null) {
      page = "?page=" + pageNo;
    }

    if (sortField !== undefined) {
      sortFieldName = "&sortField=" + sortField + "&sortOrder=" + sortOrder;
    }

    if (globalFilter != null && globalFilter !== undefined) {
      globalSearch = "&filters[global]=" + globalFilter;
    }

    if (rows !== 0) {
      perPage = "&per_page=" + rows;
    }

    const finalFilter = page + sortFieldName + globalSearch + perPage;

    return this.http.get(this.baseUrl + "/list" + finalFilter);
  }

  /**
   * Get User By ID
   * @param id
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  getUserById(id: any) {
    return this.http.get(this.baseUrl + "/view/" + id);
  }

  /**
   * It will store user
   * @param User
   * @author Vijay Vyas <vijay.vyas@globalgenecopr.com>
   */
  createUser(User) {
    return this.http.post(this.baseUrl + "/add", User);
  }

  /**
   * Delete User
   * @param id
   * @author Vijay Vyas <vijay.vyas@globalgenecopr.com>
   */
  deleteUser(id: number) {
    return this.http.delete(this.baseUrl + "/" + id);
  }

  /**
   * It will get current user
   * @author Pramod Kushwaha
   */
  getCurrentUser() {
    return this.http.get(this.baseUrl + "/profile").pipe(
      map((user) => {
        return user;
      }),
      catchError(this.handleError)
    );
  }

  /**
   * It will update update profile
   * @param User
   * @author Pramod Kushwaha
   */
  updateProfile(User) {
    return this.http.post(this.baseUrl + "/profile", User);
  }

  /**
   * It will update profile picture
   * @param User
   * @author Pramod Kushwaha
   */
  updateProfileImage(User) {
    return this.http.post(this.baseUrl + "/profileimage", User);
  }

  getProfileImage() {
    return this.http.get<any>(`${environment.baseURL}user/profileimage`).pipe(
      map((user) => {
        return user;
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    // console.log(error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: "Oops!",
      errorDesc: "Something bad happened. Please try again later.",
    };
    return throwError(this.errorData);
  }

  /**
   * It will store user
   * @param User
   * @author Pramod Kushwaha
   */
  registerUser(User) {
    return this.http.post(this.baseUrl + "/invite", User);
  }

  /**
   * @author Pramod Kushwaha get EmailId from token
   * @param token
   */
  getEmail(token) {
    return this.http.get(this.baseUrl + "/validate/" + token);
  }

  /**
   * It will activate user
   * @param User
   * @author Pramod
   */
  activateUser(User) {
    return this.http.post(`${environment.baseURL}` + "register", User);
  }

  /**
   * Resend Invite Link
   * @param id
   * @author Pramod Kushwaha
   */
  resendInviteLink(id: number) {
    return this.http.post(this.baseUrl + "/resend-invite/" + id, id);
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to check unique validation
   * @param email
   */
  checkEmailUnique(email) {
    return this.http.get(this.baseUrl + "/check-unique-email/" + email);
  }

  /**
   * get Site Admin Listing (Active)
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  getSiteAdmin() {
    return this.http.get(`${environment.baseURL}` + "getSiteAdmin");
  }

  /**
   * Update user Details
   * @param id
   * @author Pramod Kushwaha
   */
  update(body: any, id: number) {
    return this.http.post(this.baseUrl + "/update/" + id, body);
  }

  /**
   * Get users by roles
   * @param body
   */
  getUsersByRoles(body: any) {
    return this.http.post(`${this.baseUrl}/users-by-roles`, body);
  }
}
