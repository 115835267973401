import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router, Data } from '@angular/router';
import { DatePipe } from '@angular/common';
import { RoleService } from '../../../pages/service/role.service';
import { UserService } from '../../../pages/service/user.service';
import { CompareValidatorsDirective } from '../../../shared/directives/compare-validators.directive';
import { NbSpinnerService, NbToastrService } from '@nebular/theme';
import { DictionaryService } from '../../../pages/service/dictionary.service';
import {AppConstants} from '../../../app.constant';

@Component({
  selector: 'ngx-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],

})
export class RegisterComponent implements OnInit {

  public userForm: FormGroup;
  public title: string;
  public userID: any;
  public userDetail: any;
  public roleList: any;
  buttonText: string;
  private formSumitAttempt: boolean;
  public selectedImage: string = '';
  public genderList: any;
  public countryCode: any;
  public hideForm: boolean = false;
  public checkOther: boolean = false;
  public minDateValue: any;
  public maxDateValue: any;
  public curentyear = new Date().getFullYear();

  /**
  * @param fb
  * @param toastr
  * @author Pramod Kushwaha
  */
  constructor(
    private fb: FormBuilder,
    protected router: Router,
    private route: ActivatedRoute,
    private roleService: RoleService,
    private userService: UserService,
    private datePipe: DatePipe,
    private spinner: NbSpinnerService,
    private dicService: DictionaryService,
    private toastrService: NbToastrService,
  ) {
    const userId = localStorage.getItem('userId');
    if (userId !== null && userId !== '') {
      this.router.navigate(['/dashboard']);
    }
    this.spinner.load();
    this.userID = this.route.snapshot.paramMap.get('id');
    const token = this.route.snapshot.paramMap.get('token');
    this.getUserDetails(token);
    if (this.userID) {
      this.title = 'Edit User';
      this.buttonText = 'Save';
    } else {
      this.title = 'Add User';
      this.buttonText = 'Submit';
      this.userID = 0;
    }

  }


  // datepicker
 public onShowPicker(event) {
    const dayHoverHandler = event.dayHoverHandler;
    const hoverWrapper = ($event) => {
        const cell = $event;
        cell.isHovered = false;
        return dayHoverHandler($event);
    };
    event.dayHoverHandler = hoverWrapper;
}

  /**
  * It will remove white space
  * @param control
  * @author Pramod Kushwaha
  */
  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'required': true };
  }

  /**
  *
  * @param field
  * @author Pramod Kushwaha
  */
  isFieldValid(field: string) {
    return (
      (!this.userForm.get(field).valid && this.userForm.get(field).touched) ||
      (this.userForm.get(field).untouched && this.formSumitAttempt)
    );
  }

  /**
  *
  * @param field
  * @author Pramod Kushwaha
  */
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field),
    };
  }

  /**
  * Validate all form
  * @param formGroup
  * @author Pramod Kushwaha
  */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }


  /**
  * It will initialize all validator
  * @author Pramod Kushwaha
  */
  ngOnInit() {

    this.getAllDictionaries();
    this.getCountryWisePhoneCode();
    this.getCurrentLocationDetails();
    this.userForm = this.fb.group({
      'firstName': ['', [Validators.required, this.noWhitespaceValidator]],
      'middleName': ['', ''],
      'lastName': ['', [Validators.required, this.noWhitespaceValidator]],
      'gender': ['', Validators.required],
      'gender_other': ['', ''],
      'dob': ['', Validators.required],
      'phoneNumberCode': [null, ''],
      'phoneNumber': ['', ''],
      'email': ['', ''],
      'password': ['', Validators.compose([
        Validators.required,
        Validators.minLength(6),
      ])],
      'retypePassword': ['', Validators.required],
    }, {
        validator: CompareValidatorsDirective.MatchPassword,
      });

    this.userForm.controls.password.valueChanges
      .subscribe(
        x => this.userForm.controls['retypePassword'].updateValueAndValidity(),
      );

    const newdate = new Date();
    this.minDateValue = new Date(1950, newdate.getMonth(), newdate.getDate());
    this.maxDateValue = new Date();
    this.requiredFields();
  }

  /**
   * get Active Role
   * @author Pramod Kushwaha
   */
  getActiveRole() {
    this.roleService.getActiveRole().subscribe(
      (result: Data) => {
        if (result.status === 1) {
          this.roleList = result.data;
        }
      },
      err => {
        this.toastrService.danger('Something went wrong. Please contact to administrator.', 'OOPS!');
      },
      () => {

      });
  }

  /**
    *
    * @param date
    */
  transformDate(date) {
    return this.datePipe.transform(date.toString(), 'yyyy-MM-dd');
  }

  /**
 * It will create/update user
 * @author Pramod Kushwaha
 */
  onSubmit() {
    if (this.userForm.invalid) {
      this.validateAllFormFields(this.userForm);
      return;
    }

    if (this.userID === 0) {
      const formData: FormData = new FormData();
      formData.append(`first_name`, this.userForm.controls['firstName'].value);
      formData.append(`middle_name`, this.userForm.controls['middleName'].value);
      formData.append(`last_name`, this.userForm.controls['lastName'].value);
      formData.append('date_of_birth', this.transformDate(this.userForm.controls['dob'].value));
      formData.append('email', this.userForm.controls['email'].value);
      formData.append('gender_id', this.userForm.controls['gender'].value);
      formData.append(`mobile`, this.userForm.controls['phoneNumber'].value);
      formData.append(`password`, this.userForm.controls['password'].value);

      if ((this.userForm.controls['phoneNumberCode'].value !== '') &&
        (this.userForm.controls['phoneNumberCode'].value !== null)) {
        formData.append(`mobile_country_id`, this.userForm.controls['phoneNumberCode'].value);
      }

      if (this.userForm.controls['gender'].value !== 4) {
        formData.append(`gender_other`, '');
      } else {
        formData.append(`gender_other`, this.userForm.controls['gender_other'].value);
      }

      this.userService.activateUser(formData).subscribe(
        (result: Data) => {
          if (result.status === 1) {
            localStorage.setItem('successMsg', result.message);
            this.router.navigate(['/message/success']);
          } else {
            this.toastrService.danger('Please check all the fields', 'OOPS!');
            if (typeof (result.message) === 'object') {
              for (const key in result.message) {
                if (key === 'last_name') {
                  this.userForm.get('lastName').setErrors({ 'validField': result.message[key] });
                } else if (key === 'first_name') {
                  this.userForm.get('firstName').setErrors({ 'validField': result.message[key] });
                } else if (key === 'gender_id') {
                  this.userForm.get('gender').setErrors({ 'validField': result.message[key] });
                } else if (key === 'mobile') {
                  this.userForm.get('phoneNumber').setErrors({ 'validField': result.message[key] });
                } else {
                  this.userForm.get(key).setErrors({ 'validField': result.message[key] });
                }
              }
            }
          }
        },
        err => {
          this.toastrService.danger('Something went wrong. Please contact to administrator.', 'OOPS!');
        },
        () => {

        });
    }
  }

  /**
* @author Pramod Kushwaha
*
* This function is used to get gender dictionary
*/
  getAllDictionaries() {
    this.dicService.getGenderList().subscribe((result: Data) => {
      this.genderList = result.data;
    }, err => {

    }, () => {
    });
  }

  /**
* @author Pramod Kushwaha
*
* This function is used to get gender dictionary
*/
  getCountryWisePhoneCode() {
    this.dicService.getCountryList().subscribe((result: Data) => {
      this.countryCode = result.data;
    }, err => {
    }, () => {
    });
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get email address
   */
  getUserDetails(token) {
    this.userService.getEmail(token).subscribe(
      (result: Data) => {
        if (result.status !== AppConstants.serverSuccessStatus) {
          localStorage.setItem('errorMsg', result.message);
          this.router.navigate(['/message/error']);
        }
        this.hideForm = true;
        this.userDetail = result.data;

        const roleArr = [];
        this.userDetail.roleList.forEach((res) => {
          roleArr.push(res.role_name);
        });
        this.roleList = roleArr.join(',');
        this.userForm.patchValue({
          email: this.userDetail.email,
        });
        this.userForm.controls['email'].disable();
      },
      err => {
        this.toastrService.danger('Something went wrong. Please contact to administrator.', 'OOPS!');
      },
      () => {

      },
    );
  }

  /**
  *
  * @param event
  * @author Pramod Kushwaha
  */
  keyPress(event: any) {
    const pattern = /^([0-9]+)$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  /**
 *
 * @param event
 * @author Pramod Kushwaha
 */
  keyChange(event: any) {
    const pattern = /^([0-9]+)$/;
    if (!pattern.test(event.target.value) && (event.target.value !== '')) {
      this.userForm.get('phoneNumber').setErrors({ 'invalidPhone': 'Please Enter valid Phone Number' });
    }
  }
  /**
    * @author Pramod Kushwaha
    * This function is used to check Other or not
    * @param value
    */
  selectGender(value) {
    if (value === 4) {
      this.checkOther = true;
    } else {
      this.checkOther = false;
    }
  }

  /**
   * @author Pramod Kushwaha
   * This funtion is used to require phonnumber field
   */
  requiredFields() {
    const phoneCode = this.userForm.get('phoneNumberCode');
    this.userForm.get('phoneNumber').valueChanges.subscribe(
      phoneNumber => {
        if (phoneNumber.length > 0) {
          phoneCode.setValidators([Validators.required]);
        } else {
          phoneCode.setValidators(null);
        }
        phoneCode.updateValueAndValidity();
      },
    );
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get Location details
   */
  getCurrentLocationDetails() {
    this.dicService.getCurrentLocationDetails().subscribe((result: Data) => {
      this.userForm.get('phoneNumberCode').setValue(result.data.country_id);
      // // console.log(result);
    }, err => {
    }, () => {
    });
  }

}
