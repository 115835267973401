import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'actionHeaderPermission',
})
export class ActionHeaderPermissionPipe implements PipeTransform {

  transform(col: any, currentMenuPermission: any): any {
    if (col) {
      return col.filter(object => {
          // console.log('vic column: ', col.field);
          // console.log('currentMenuPermission.edit: ', currentMenuPermission.edit);

          return col.field === 'action'
          && (currentMenuPermission.edit === true || currentMenuPermission.delete === true);
      });
    }
  }

}
