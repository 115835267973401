import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
      <span class="created-by"> © {{curentyear}} Global Gene Corp. All Rights Reserved. </span>
  `,
})
export class FooterComponent {
  public curentyear = new Date().getFullYear();
}
