import {AbstractControl} from '@angular/forms';
export class CompareValidatorsDirective {

  /**
   * IT will match password. This is generally used while creating form.
   * @param control
   * @constructor
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  static MatchPassword(control: AbstractControl) {

    const password = control.get('password').value;
    const confirmPassword = control.get('retypePassword').value;

    if (confirmPassword === '' || password === '') {
      return null;
    }

    if (password !== confirmPassword) {
      control.get('retypePassword').setErrors( {ConfirmPassword: true} );
    } else {
      /*control.get('retypePassword').setErrors( {ConfirmPassword: false} );
      control.get('retypePassword').setErrors( {erros: false} );*/
      return null;
    }
  }

  /**
   * It will check matching passwords
   * @param control
   * @constructor
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  static MatchPasswordEdit(control: AbstractControl) {

    const password = control.get('password').value;
    const confirmPassword = control.get('retypePassword').value;

    if (confirmPassword === '' && password === '') {
      return null;
    }

    if (confirmPassword === '' && password !== '') {
      return null;
    }

    if ((confirmPassword === '' && password !== '') || (password !== confirmPassword)) {
      control.get('retypePassword').setErrors( {ConfirmPassword: true} );
    } else {
      /*control.get('retypePassword').setErrors( {ConfirmPassword: false} );
      control.get('retypePassword').setErrors( {erros: false} );*/
      return null;
    }
  }
}
