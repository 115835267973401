import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { ThankyouComponent } from './components/thankyou/thankyou.component';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { AuthGuard } from './../auth-guard.service';
import { HeaderComponent } from '../@theme/components';
import { RegisterComponent } from './components/register/register.component';
import { AuthComponent } from './auth.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'password/reset/:token', component: ForgotpasswordComponent, data: { breadcrumb: 'Forgot Password' } },
      { path: 'reset-password/:token', component: ResetpasswordComponent, data: { breadcrumb: 'Reset Password' } },
      { path: 'message/:type', component: ThankyouComponent, data: { breadcrumb: 'Thank You' } },
      { path: 'register/:token', component: RegisterComponent, data: { breadcrumb: 'Register User' } },
      { path: 'logout', component: HeaderComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthGuard],
})

export class AuthRoutingModule {}
