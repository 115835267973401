import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { throwError } from 'rxjs';
import {Data} from './auth-interceptor';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  isLoggedIn = false;
  redirectUrl: string;
  errorData: {};
  userRes: any;


  constructor(private http: HttpClient,
              private router: Router) { }

  /**
   * Login function
   * @param username
   * @param password
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  sendOTP(username: string, password: string) {
    const body = new HttpParams()
      .set(`email`, username)
      .set(`password`, password);
    return this.http.post<any>(`${environment.baseURL}sendOTP`, body);
  }


  /**
   * Login function
   * @param username
   * @param password
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  loginStep2(username: string, password: string, otp: string) {
    const body = new HttpParams()
      .set(`email`, username)
      .set(`password`, password)
      .set(`otp`, otp);

    return this.http.post<any>(`${environment.baseURL}login`, body);
  }

  /**
   * reset password update
   * @param token
   * @param password
   * @param confirm_password
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  resetpassword(token: string, password: string) {
    const body = new HttpParams()
      .set(`token`, token)
      .set(`password`, password);

    return this.http.post<any>(`${environment.baseURL}password/reset`, body);
  }

  /**
   * Logout
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  logout() {
    this.http.get(`${environment.baseURL}logout`).subscribe(
        (result: Data) => {

          localStorage.removeItem('userId');
          localStorage.removeItem('userInfo');
          localStorage.clear();
          this.router.navigate(['login']);
        },
        err => {
          localStorage.removeItem('userId');
          localStorage.removeItem('userInfo');
          localStorage.clear();
        },
        () => {
          localStorage.removeItem('userId');
          localStorage.removeItem('userInfo');
          localStorage.clear();
        });
  }

  /**
   * It will handle error
   * @param error
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The response body may contain clues as to what went wrong.
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: 'Oops!',
      errorDesc: 'Something bad happened. Please try again later.',
    };
    return throwError(this.errorData);
  }

  /**
   * get Authorization token
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  getAuthorizationToken() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo) {
      return userInfo.api_key;
    }
    return null;
  }


  /**
   * Forgot password
   * @param username
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  forgotPassword(username: string) {
    const body = new HttpParams()
      .set(`email`, username);
    return this.http.post<any>(`${environment.baseURL}forgotpassword`, body);
  }

  /**
   * Reset password token check (Valid/Invalid)
   * @param token
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  checkResetPasswordToken(token: string) {
    return this.http.get<any>(`${environment.baseURL}password/reset?token=` + token);
  }

  /**
   * Refresh Token
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  refreshToken() {
    return this.http.get<any>(`${environment.baseURL}refresh-token`);
  }


}
