import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AuthService } from '../../service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Data } from '../../service/auth-interceptor';
import { DeviceDetectorService } from 'ngx-device-detector';
import {CompareValidatorsDirective} from '../../../shared/directives/compare-validators.directive';
import {NbSpinnerService} from '@nebular/theme';
import {AppConstants} from '../../../app.constant';


@Component({
    templateUrl: './forgotpassword.component.html',
    styleUrls: ['./forgotpassword.component.scss'],
})
export class ForgotpasswordComponent implements OnInit {

    public resetPasswordForm: FormGroup;
    public submitted = false;
    public errors: string[] = [];
    public messages: string[] = [];
    returnUrl: string;
    public token: any = 'test';
    public formSumitAttempt: boolean;
    deviceInfo = null;
    public inValidToken: boolean = false;
    public errorMsg: any;
    public successMsg: any;
    public thankYou: boolean = false;
    public loading: boolean = false;
    public hideBody: any;

    /**
     * Password reset
     * @param formBuilder
     * @param router
     * @param authService
     * @param route
     * @param http
     * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
     */
    constructor(private formBuilder: FormBuilder,
        protected router: Router,
        private authService: AuthService,
        private route: ActivatedRoute,
        private http: HttpClient,
                private spinner: NbSpinnerService,
        private deviceService: DeviceDetectorService) {
        this.token = this.route.snapshot.paramMap.get('token');
        this.resetPasswordForm = this.formBuilder.group({
            'password': ['', Validators.compose([
                Validators.minLength(6),
            ])],
            'retypePassword': ['', Validators.required],
        }, {
                validator: CompareValidatorsDirective.MatchPasswordEdit,
            });

      this.spinner.load();

        // It will check reset password token
        // this.redirectApps();
        this.checkResetPasswordToken();
    }

    /**
    * It will check token is valid or not
    * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
    */
    checkResetPasswordToken() {
        this.authService.checkResetPasswordToken(this.token)
            .subscribe((result: Data) => {
                if (result.status === AppConstants.serverFailStatus) {
                  this.inValidToken = true;
                  this.errorMsg = result.message;
                }
            }, err => {
                this.errors.push('Oops! Something went wrong. Please contact to administrator.');
            }, () => {
            },
            );
    }

    get password() { return this.resetPasswordForm.get('password'); }
    get confirm_password() { return this.resetPasswordForm.get('retypePassword'); }

    /**
     * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
     */
    ngOnInit() {
        this.resetPasswordForm.controls.password.valueChanges
            .subscribe(
                x => this.resetPasswordForm.controls['retypePassword'].updateValueAndValidity(),
            );


    }

    /**
    * Reset Password
    * @param event
    * @author Jaydeep Patel <jaydeep.patel@globalgenecorp.com>
    */
    onSubmit() {
        if (this.resetPasswordForm.invalid) {
            this.validateAllFormFields(this.resetPasswordForm);
            return;
        }

        this.loading = true;
        this.hideBody = 'hideBody';

        this.authService.resetpassword(this.token, this.password.value)
            .subscribe((result: Data) => {
                if (result.status === AppConstants.serverSuccessStatus) {
                  this.successMsg = result.message;
                  this.thankYou = true;
                  this.loading = false;
                  this.hideBody = '';
                }
            }, err => {
                this.loading = false;
                this.hideBody = '';
                this.errors = [];
                this.errors.push('Oops! Something went wrong. Please contact to administrator.');
            }, () => {
              this.loading = false;
              this.hideBody = '';
              },
            );
    }


    /**
     *
     * @param field
     */
    isFieldValid(field: string) {
        return (
            (!this.resetPasswordForm.get(field).valid && this.resetPasswordForm.get(field).touched) ||
            (this.resetPasswordForm.get(field).untouched && this.formSumitAttempt)
        );
    }

    /**
     *
     * @param field
     */
    displayFieldCss(field: string) {
        return {
            'has-error': this.isFieldValid(field),
            'has-feedback': this.isFieldValid(field),
        };
    }


    /**
     *
     * @param formGroup
     */
    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    /**
     * @author Pramod kushwaha
     * This function is used to redirect reset password on mobile Apps
     */
    redirectApps() {
        this.deviceInfo = this.deviceService.getDeviceInfo();
        const isMobile = this.deviceService.isMobile();
        const isTablet = this.deviceService.isTablet();
        const isDesktopDevice = this.deviceService.isDesktop();
        const os = this.deviceInfo.os;

        if (isMobile === true) {
            if (os === 'iOS') {
                window.location.href = 'com.ggcMEgreen://reset_password?token=' + this.token;
                return false;
            }
        }

    }


}
