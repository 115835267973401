import { ThemeModule } from '../@theme/theme.module';
import { AuthRoutingModule } from './auth-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ThankyouComponent } from './components/thankyou/thankyou.component';
import { NgFlashMessagesModule } from 'ng-flash-messages';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { NgSelectModule, NgSelectConfig } from '@ng-select/ng-select';
import { RegisterComponent } from './components/register/register.component';
import {NbDatepickerModule, NbLayoutModule} from '@nebular/theme';
import { AuthComponent } from './auth.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [
    LoginComponent,
    ForgotpasswordComponent,
    ThankyouComponent,
    ResetpasswordComponent,
    RegisterComponent,
    AuthComponent,
  ],
  imports: [
    CommonModule,
    NbLayoutModule,
    ThemeModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgFlashMessagesModule.forRoot(),
    NgSelectModule,
    NbDatepickerModule,
    AuthRoutingModule,
    BsDatepickerModule.forRoot(),
  ],
})
export class AuthModule {
  constructor(private config: NgSelectConfig) {
    this.config.notFoundText = 'No results found!';
  }
}
