import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth/service/auth.service';


@Injectable({
    providedIn: 'root',
  })
export class AuthGuard implements CanActivate {

    /**
     *
     * @param authService
     * @param router
     * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
     */
    constructor(
        private authService: AuthService,
        private router: Router) { }

    /**
     * It will check authorization
     * @param route
     * @param state
     * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const url: string = state.url;
        return this.checkLogin(url);
    }

    /**
     * It will check whether user is logged in or not
     * @param url
     * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
     */
    checkLogin(url: string) {
        const userId = localStorage.getItem('userId');
        // // console.log('userId', userId);
        if (userId !== null && userId !== '') {
            return true;
        }
        this.authService.redirectUrl = url;
        this.router.navigate(['/login'], { state: { redirect: url } });
        return false;
    }
}
