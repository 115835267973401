import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[ngxAppNoSpace]'
})
export class NoSpaceDirective {
  constructor(private el: ElementRef) {
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {

    const current: string = this.el.nativeElement.value;

    // Not Allow Space in Input field
    if (current && String(current).indexOf(' ') > 0) {
      this.el.nativeElement.value = current.split(' ').join('');
      event.preventDefault();
    }
  }
}
