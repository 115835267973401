import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router, RoutesRecognized } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { Data } from '../../service/auth-interceptor';
import { NbSpinnerService } from '@nebular/theme';
import { AppConstants } from '../../../app.constant';
import { timer } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit {

  private formSumitAttempt: boolean;

  public loginForm: FormGroup;
  public login2Form: FormGroup;
  public forgotPasswordForm: FormGroup;
  public otpSend: boolean = false;
  public disabled: any;
  public hideBody: any;
  public errors: string[] = [];
  public step1: boolean = true;
  public step2: boolean = false;
  public dEmail: string;
  public disabledOTP: any;
  public randomRedirect: any;
  public expiredOTP = false;
  public forgotpass: boolean = false;
  public errors3: string[] = [];
  public messages: string[] = [];
  public loading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    private authService: AuthService,
    private http: HttpClient,
    private spinner: NbSpinnerService,
  ) {

    this.loginForm = this.formBuilder.group({
      'password': ['', Validators.required], // admin123
      'email': ['', Validators.compose([
        Validators.required,
        Validators.email,
      ])],
    });

    this.login2Form = this.formBuilder.group({
      'otp': ['', Validators.required],
    });


  }

  get username() {
    return this.loginForm.get('email');
  }

  get password() {
    return this.loginForm.get('password');
  }

  get otp() {
    return this.login2Form.get('otp');
  }

  /**
  * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
  */
  ngOnInit() {

    // console.log('type of : ', typeof localStorage.userId);
    // console.log('value : ', localStorage.userId);

    // this.router.events
    // .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
    // .subscribe((events: RoutesRecognized[]) => {
    //   console.log('previous url', events[0].urlAfterRedirects);
    //   console.log('current url', events[1].urlAfterRedirects);
    // });

    if ((localStorage.userId !== undefined)) {
      this.router.navigate(['/dashboard']);
      return;
    }

    // this.spinner.load();
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('login-page');
    body.classList.add('hold-transition');
  }

  /**
   * only validation number
   * @param event
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  onlyNumber(event) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k >= 48 && k <= 57 || k === 13));
  }


  /**
   *
   * @param field
   */
  isFieldValid(field: string, frmgrp: FormGroup) {
    return (
      (!frmgrp.get(field).valid && frmgrp.get(field).touched) ||
      (frmgrp.get(field).untouched && this.formSumitAttempt)
    );
  }

  /**
   *
   * @param field
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  displayFieldCss(field: string, formGroup: FormGroup) {
    return {
      'has-error': this.isFieldValid(field, formGroup),
      'has-feedback': this.isFieldValid(field, formGroup),
    };
  }

  /**
   *
   * @param formGroup
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }



  /**
   * Send OTP
   * @param event
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  onSubmit() {
    if (this.loginForm.invalid) {
      this.validateAllFormFields(this.loginForm);
      return;
    }
    this.disabled = true;
    this.loading = true;
    this.hideBody = 'hideBody';
    this.errors = [];
    this.authService.sendOTP(this.username.value, this.password.value).subscribe(
      (result: Data) => {

        if (result.status === AppConstants.serverSuccessStatus) {
          this.loading = false;
          this.step1 = false;
          this.step2 = true;
          this.hideBody = '';
          this.dEmail = this.username.value;
          setTimeout(() => { this.expiredOTP = true; }, 180000);
        } else {
          this.errors.push(result.message);
        }
      },
      err => {
        // // console.log(err);
        this.hideBody = '';
        this.loading = false;
        this.disabled = false;
        this.errors.push('Oops! Something went wrong. Please contact to administrator.');
      },
      () => {
        this.hideBody = '';
        this.loading = false;
        this.disabled = false;
      },
    );
  }

  /**
   * It will check OTP
   * @param event
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  checkOTP() {
    if (this.login2Form.invalid) {
      this.validateAllFormFields(this.login2Form);
      return;
    }
    this.errors = [];
    this.otpSend = false;

    this.hideBody = 'hideBody';
    this.loading = true;
    this.disabledOTP = true;

    this.authService.loginStep2(this.username.value, this.password.value, this.otp.value).subscribe(
      (result: Data) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          localStorage.setItem('userId', result.data.user_id);
          localStorage.setItem('userInfo', JSON.stringify(result.data));
          const { redirect } = window.history.state;
          // this.router.navigateByUrl(redirect || '/dashboard');
          this.router.navigate(['/dashboard']);
        } else if (result.status === 2) {
          this.errors = [];
          // this.expiredOTP = true;
          this.login2Form.patchValue({ 'otp': null });
          this.loading = false;
          this.hideBody = '';
          this.errors.push(result.message);
        } else {
          this.loading = false;
          this.hideBody = '';
          this.errors = [];
          this.errors.push(result.message);
        }
      },
      err => {
        this.loading = false;
        this.errors.push('Oops! Something went wrong. Please contact to administrator.');
      },
      () => {
        this.disabledOTP = false;
      },
    );
  }



  /**
   * resend otp
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  resendOTP() {
    this.errors = [];
    this.otpSend = false;
    this.expiredOTP = false;
    this.authService.sendOTP(this.username.value, this.password.value).subscribe(
      (result: Data) => {
        if (result.status === 1) {
          this.errors = [];
          this.dEmail = this.username.value;
          setTimeout(() => { this.expiredOTP = true; }, 180000);
        } else {
          this.errors.push('Invalid Username/Password');
          this.backToLogin();
        }

      },
      err => {
        this.errors.push('Oops! Something went wrong. Please contact to administrator.');
      },
      () => {
        setTimeout(function () {
          this.otpSend = false;
        }, 1000);

      },
    );
  }

  /**
   * Back to email form after reseting value
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  backToEmail() {
    this.errors = [];
    this.step1 = true;
    this.step2 = false;
    this.forgotpass = false;

    this.loginForm.patchValue({
      email: this.username.value,
      password: '',
    });
    this.forgotPasswordForm.patchValue({
      email: '',
    });
    this.login2Form.patchValue({
      otp: '',
    });
  }



  /**
   *
   */
  backToLogin() {
    this.errors = [];
    this.forgotpass = false;
    this.step1 = true;
    this.step2 = false;
    this.forgotpass = false;
    this.loginForm.patchValue({
      email: '',
      password: '',
    });
    this.forgotPasswordForm.patchValue({
      email: '',
    });

    this.login2Form.patchValue({
      otp: '',
    });

  }


  /**
   *
   */
  forgotPassword() {
    this.errors = [];
    this.forgotpass = true;
    this.step1 = false;
    this.step2 = false;
    this.messages = [];
    this.errors3 = [];

    this.loginForm.reset();
    this.forgotPasswordForm = this.formBuilder.group({
      'email': ['', Validators.compose([
        Validators.required,
        Validators.email,
      ])],
    });
  }

  get emails() { return this.forgotPasswordForm.get('email'); }

  /**
   * It will send link to registered user
   * @param event
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  checkForgotPassword() {
    if (this.forgotPasswordForm.invalid) {
      this.validateAllFormFields(this.forgotPasswordForm);
      return;
    }
    this.disabled = true;
    this.loading = true;
    this.hideBody = 'hideBody';
    this.errors3 = [];
    this.messages = [];
    this.authService.forgotPassword(this.emails.value)
      .subscribe((result: Data) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          this.messages = [];
          localStorage.setItem('successMsg', result.message);
          this.messages.push(result.message);
          this.forgotPasswordForm.reset();
        } else {
          this.errors3 = [];
          this.errors3.push(result.message);
        }
      },
        err => {
          this.loading = false;
          this.errors.push('Oops! Something went wrong. Please contact to administrator.');
          this.disabled = false;
        },
        () => {
          this.hideBody = '';
          this.loading = false;
          this.disabled = false;
        },
      );
  }


}
