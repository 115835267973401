import { of as observableOf,  Observable, Subject  } from 'rxjs';
import { Injectable } from '@angular/core';
import { Contacts, RecentUsers, UserData } from '../data/users';

@Injectable()
export class UserService extends UserData  {

  private time: Date = new Date;

  public userInfo = localStorage.getItem('userInfo');


  // private users = {
  //   john: { name: 'John Williamsdd', picture: 'assets/images/john.png' },
  //   eva: { name: 'Eva Moor', picture: 'assets/images/eva.png' },
  //   jack: { name: 'Jack Williams', picture: 'assets/images/jack.png' },
  //   lee: { name: 'Lee Wong', picture: 'assets/images/lee.png' },
  //   alan: { name: 'Alan Thompson', picture: 'assets/images/alan.png' },
  //   kate: { name: 'Kate Martinez', picture: 'assets/images/kate.png' },
  // };

  private users = localStorage.getItem('userInfo');

  private types = {
    mobile: 'mobile',
    home: 'home',
    work: 'work',
  };
  private contacts: Contacts[] = [];
  private recentUsers: RecentUsers[]  = [];

  profileImageUpdate$ = new Subject<string>();
  firstName$ = new Subject<string>();
  lastName$ = new Subject<string>();

  getUsers(): Observable<any> {
    return observableOf(this.users);
  }

  getContacts(): Observable<Contacts[]> {
    return observableOf(this.contacts);
  }

  getRecentUsers(): Observable<RecentUsers[]> {
    return observableOf(this.recentUsers);
  }
}
