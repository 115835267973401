import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {BehaviorSubject, throwError} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuService {

  errorData: {};
  public menuList = new BehaviorSubject<any[]>([]);
  public userInfo = new BehaviorSubject<any[]>([]);
  public currentMenu = new BehaviorSubject<any[]>([]);

  constructor(private http: HttpClient) { }

  /**
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  getMenu() {
    return this.http.get<any>(`${environment.baseURL}menu`)
        .pipe(map(user => {
              return user;
            }),
            catchError(this.handleError),
        );
  }


  getMenuInfo(data) {
    return this.http.post(`${environment.baseURL}menu/info`, data);
  }

  private handleError(error: HttpErrorResponse ) {
    // console.log(error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The response body may contain clues as to what went wrong.
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: 'Oops!',
      errorDesc: 'Something bad happened. Please try again later.',
    };
    return throwError(this.errorData);
  }



  /**
   * It will get current user
   * @author Pramod Kushwaha
   */
  getCurrentUser() {
    return this.http.get(`${environment.baseURL}` + 'user/profile').pipe(map(user => {
          return user;
        }),
        catchError(this.handleError),
    );
  }

  /**
   * Get the un approved dictionaries
   */
 /*  getHeaderNotification() {
    return this.http.get<any>(`${environment.baseURL}header/notifications`);
  } */

  /**
   * @author Mahendra Sharma <mahendra.sharma@globalgenecorp.com>
   */
  sendPushNotificationToMobile() {
    return this.http.post(`${environment.baseURL}notification/sendtoall`,[]);
  }
}
