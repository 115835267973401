import { AfterViewInit, Component, Inject, PLATFORM_ID, ViewChild, HostListener } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NbLayoutComponent } from '@nebular/theme';
import { UserData } from '../../../@core/data/users';
import * as $ from 'jquery';
import { WindowModeBlockScrollService } from '../../services/window-mode-block-scroll.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AppConstants } from '../../../app.constant';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
  <nb-layout>
  <nb-layout-header fixed>
    <ngx-header></ngx-header>
  </nb-layout-header>

  <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
    <nb-sidebar-header></nb-sidebar-header>
    <ng-content select="nb-menu"></ng-content>
  </nb-sidebar>

  <nb-layout-column>
    <ng-content select="router-outlet"></ng-content>
  </nb-layout-column>

  <nb-layout-footer fixed>
    <ngx-footer></ngx-footer>
  </nb-layout-footer>
</nb-layout>
  `,
})
export class OneColumnLayoutComponent implements AfterViewInit {


  @ViewChild(NbLayoutComponent, { static: false }) layout: NbLayoutComponent;

  user: any;
  private destroy$: Subject<void> = new Subject<void>();
  userPic: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private userService: UserData,
    private windowModeBlockScrollService: WindowModeBlockScrollService,
  ) {
    this.getUser();
    this.userPic = '../../../assets/images/' + AppConstants.defaultProfileImage;
  }

  getUser() {
    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = JSON.parse(users));
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.windowModeBlockScrollService.register(this.layout);
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    const scrollHeight = $(document).height();
    const scrollPosition = $(window).height() + $(window).scrollTop();


    const nav = document.getElementsByTagName('nav')[0];
    const body = document.getElementsByTagName('body')[0];

    if ($(window).scrollTop() > 10) {
      nav.classList.add('header-small');
      body.classList.add('header-small');
    } else {
      nav.classList.remove('header-small');
      body.classList.remove('header-small');
    }
  }
}
