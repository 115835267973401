import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[ngxBlockCopyPaste]',
})
export class BlockCopyPasteDirective {
  constructor() {}

  /**
   *
   * @param e
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  /**
   *
   * @param e
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  /**
   *
   * @param e
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }
}
