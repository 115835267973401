import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { HttpClient } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Data } from '../../service/auth-interceptor';
import * as $ from 'jquery';


@Component({
  selector: 'ngx-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss'],
})
export class ResetpasswordComponent implements OnInit {

  public resetPasswordForm: FormGroup;
  public submitted = false;
  public error: any;
  public message: any;
  returnUrl: string;
  public token: any = 'test';
  public formSumitAttempt: boolean;
  deviceInfo = null;

  public formStatus: boolean = true;
  public successStatus: boolean = false;
  public failStatus: boolean = false;




  /**
   * Password reset
   * @param formBuilderresetPasswordForm
   * @param router
   * @param authService
   * @param route
   * @param http
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  constructor(private formBuilder: FormBuilder,
    protected router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private deviceService: DeviceDetectorService) {

    this.token = this.route.snapshot.paramMap.get('token');

    this.resetPasswordForm = this.formBuilder.group({
      'password': ['', [Validators.required, Validators.minLength(6)]],
    });

    // It will check reset password token
    this.redirectApps();
    this.checkResetPasswordToken();
  }

  /**
   * It will check token is valid or not
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  checkResetPasswordToken() {
    this.authService.checkResetPasswordToken(this.token)
      .subscribe((result: Data) => {
        if (result.status !== 1) {
          this.failStatus = true;
          this.successStatus = false;
          this.formStatus = false;
          this.error = result.message;
        }
      }, err => {
        this.failStatus = true;
        this.successStatus = false;
        this.formStatus = false;
        this.error = 'Opps! Something went wrong. Please contact to administrator.';

      }, () => {

      },
      );
  }

  get password() { return this.resetPasswordForm.get('password'); }

  /**
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  ngOnInit() {
    $(document).ready(function () {
      $('.show-pw-field i').on('click', function () {
        $('.show-pw-field input').toggleClass('active');
        if ($('.show-pw-field input').hasClass('active')) {
          $(this).attr('class', 'fa fa-eye fa-lg')
            .prev('input').attr('type', 'text');
        } else {
          $(this).attr('class', 'fa fa-eye-slash fa-lg')
            .prev('input').attr('type', 'password');
        }
      });
    });
  }

  /**
   * Reset Password
   * @param event
   * @author Jaydeep Patel <jaydeep.patel@globalgenecorp.com>
   */
  onSubmit() {

    if (this.resetPasswordForm.invalid) {
      this.validateAllFormFields(this.resetPasswordForm);
      return;
    }

    this.checkResetPasswordToken();

    this.authService.resetpassword(this.token, this.password.value)
      .subscribe((result: Data) => {
        if (result.status === 1) {
          this.failStatus = false;
          this.successStatus = true;
          this.formStatus = false;
          this.message = result.message;
        }
      }, err => {
        this.failStatus = true;
        this.successStatus = false;
        this.formStatus = false;
        this.error = 'Opps! Something went wrong. Please contact to administrator.';

      }, () => {

      },
      );
  }


  /**
   *
   * @param field
   */
  isFieldValid(field: string) {
    return (
      (!this.resetPasswordForm.get(field).valid && this.resetPasswordForm.get(field).touched) ||
      (this.resetPasswordForm.get(field).untouched && this.formSumitAttempt)
    );
  }

  /**
   *
   * @param field
   */
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field),
    };
  }


  /**
   *
   * @param formGroup
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * @author Vijay Vyas
   */
  backToResetPassword() {
    this.router.navigate(['/reset-password/' + this.token]);
  }
  /**
   * @author Pramod kushwaha
   * This function is used to redirect reset password on mobile Apps
   */
  redirectApps() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    const os = this.deviceInfo.os;

    if (isMobile === true) {
      if (os === 'iOS') {
        window.location.href = 'com.ggcMEgreen://reset_password?token=' + this.token;
        return false;
      }
    }

  }
}
