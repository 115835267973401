import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { environment } from './../../../environments/environment';


@Injectable({
  providedIn: 'root',
})
export class RoleService {

  baseUrl: string = `${environment.baseURL}` + 'role';

  constructor(private http: HttpClient) { }

  /**
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  getRole() {
    return this.http.get(this.baseUrl);
  }

  /**
   * Get doctor by ID
   * @param id
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  getRoleById(id: any) {
    return this.http.get<any>(this.baseUrl + '/' + id);
  }

  /**
   * create/update doctor
   * @param data
   */
  storeRole(data) {
    // const body = new HttpParams()
    //     .set(`data`, JSON.stringify(data));
    return this.http.post(this.baseUrl, data);
  }

  /**
   * Delete Role
   * @param id
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  deleteRole(id: number) {
    return this.http.delete(this.baseUrl + '/' + id);
  }


  /**
   * get Active Role
   * @param id
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  getActiveRole() {
    return this.http.get(this.baseUrl + '/list');
  }


  /**
   * get Active Role
   * @param id
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  checkRoleUnique(name: string, id: number) {
    const body = new HttpParams()
        .set(`role_name`, name);
    if (id === null) {
      id = 0;
    }
    return this.http.post(this.baseUrl + '/unique/' + id, body);
  }
}
