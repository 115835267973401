import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { share } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class DictionaryService {
  baseUrl: string = `${environment.baseURL}` + "dictionary";

  public profileImage = new BehaviorSubject<any[]>([]);

  errorData: {};

  constructor(private http: HttpClient) {}

  /**
   * @author Pramod Kushwaha
   * This function is used to get gender list
   */
  getGenderList() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=gender_masters");
  }

  countries: Observable<any>;

  /**
   * @author Pramod Kushwaha
   * This function is used to get all country listing with flag
   */
  getCountryList() {
    if (this.countries) {
      return this.countries;
    }
    this.countries = this.http
      .get(`${this.baseUrl}/get?dictionary_type=countries`)
      .pipe(share());

    return this.countries;
  }

  /**
   * @author Pramod Kushwaha
   * This fucntion is used to get all religion name
   */
  getReligion() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=religion");
  }

  /**
   * @author Pramod Kushwaha
   * This fucntion is used to get all religion name
   */
  getSubPopulation() {
    return this.http.get(
      this.baseUrl + "/get?dictionary_type=population_group"
    );
  }

  /**
   * @author Pramod
   * This function is used to get current user location
   */
  getCurrentLocationDetails() {
    // get-country-name-from-ip
    return this.http.get(this.baseUrl + "/get-country-name-from-ip");
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get all project list
   */
  getProject() {
    return this.http.get(this.baseUrl + "/project");
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get all ethics-committee list
   */
  getEthicsCommittee(projectId) {
    return this.http.get(this.baseUrl + "/ethics-committee/" + projectId);
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get all diagnostic list
   */
  getDiagnosticLabs() {
    return this.http.get(`${this.baseUrl}/diagnostic-labs`);
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get Ethics Details
   *
   * @param EthicsId
   */
  getEthicsDetails(EthicsId) {
    return this.http.get(this.baseUrl + "/ethics-committee/view/" + EthicsId);
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get all Dictionary
   */
  getAllDictionary() {
    return this.http.get(`${environment.baseURL}` + "masterdata/info");
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get Unit Dictionary
   */
  getUnitDictionary() {
    return this.http.get(`${environment.baseURL}` + "units");
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get Unique Identity Dictionary
   */
  getUniqueIdentityDictionary() {
    return this.http.get(
      this.baseUrl + "/get?dictionary_type=unique_identity_dictionary"
    );
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get Sect Denomination Dictionary
   */
  getSectDenominationDictionary() {
    return this.http.get(
      this.baseUrl + "/get?dictionary_type=sect_denomination"
    );
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get Sub Clan Dictionary
   */
  getSubClanDictionary() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=sub_clan");
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get Languages Dictionary
   */
  getLanguagesDictionary() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=languages");
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get Language Dialect Dictionary
   */
  getLanguageDialectDictionary() {
    return this.http.get(
      this.baseUrl + "/get?dictionary_type=language_dialect"
    );
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get Countries List Dictionary
   */
  getcountryListDictionary() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=countries");
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get Disease Name List Dictionary
   */
  getDiseaseNameDictionary() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=disease_name");
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get family Member List Dictionary
   */
  getfamilyMemberDictionary() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=family_members");
  }

  /**
   * @author Farhan Shaikh
   * This function is used to get blood relatives List Dictionary
   */
   getBloodRelativesDictionary() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=blood_relatives");
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get Surgery Was Carried Out List Dictionary
   */
  getSurgeryWasCarriedOutDictionary() {
    return this.http.get(
      this.baseUrl + "/get?dictionary_type=surgery_was_carried_out"
    );
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get Treatment Given List Dictionary
   */
  getTreatmentGivenDictionary() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=treatment_given");
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get Treatment Given List Dictionary
   */
  getResponseTreatmentDictionary() {
    return this.http.get(
      this.baseUrl + "/get?dictionary_type=response_to_treatment"
    );
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get Medicine System List Dictionary
   */
  getMedicineSystemDictionary() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=medicine_system");
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get Medicine Route List Dictionary
   */
  getMedicineRouteDictionary() {
    return this.http.get(
      this.baseUrl + "/get?dictionary_type=medication_route"
    );
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get Medicine Strength List Dictionary
   */
  getMedicineStrengthDictionary() {
    return this.http.get(
      this.baseUrl + "/get?dictionary_type=medication_strength"
    );
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get Medicine Frequency List Dictionary
   */
  getMedicineFrequencyDictionary() {
    return this.http.get(
      this.baseUrl + "/get?dictionary_type=medication_frequency"
    );
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get Medicine Duration List Dictionary
   */
  getMedicineDurationDictionary() {
    return this.http.get(
      this.baseUrl + "/get?dictionary_type=medication_duration"
    );
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get Multi Birth List Dictionary
   */
  getmultiBirthDictionary() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=multiple_birth");
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get Approx Age List Dictionary
   */
  getApproxAgeDictionary() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=approximate_age");
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get Occupation Type List Dictionary
   */
  getOccupationTypeDictionary() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=occupation");
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get Occupation Type List Dictionary
   */
  getIndustryofWorkDictionary() {
    return this.http.get(
      this.baseUrl + "/get?dictionary_type=industry_of_work"
    );
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get allergy type List Dictionary
   */
  getAllergyTypeDictionary() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=allergy_type");
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get reaction type List Dictionary
   */
  getReactionTypeDictionary() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=reaction_type");
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get exercise frequency List Dictionary
   */
  getExerciseFrequencyDictionary() {
    return this.http.get(
      this.baseUrl + "/get?dictionary_type=exercise_frequency"
    );
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get Tobacco Form List Dictionary
   */
  getTobaccoFormDictionary() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=tobacco");
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get Caffeine Form List Dictionary
   */
  getCaffeineFormDictionary() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=caffeine");
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get frequency List Dictionary
   */
  getfrequencyListDictionary() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=frequency");
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get getspecialDietTypes List Dictionary
   */
  getspecialDietTypesDictionary() {
    return this.http.get(
      this.baseUrl + "/get?dictionary_type=specific_diet_type"
    );
  }
  /**
   * @author Pramod Kushwaha
   * This function is used to get dietType List Dictionary
   */
  getDietTypeDictionary() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=diet_type");
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get HighestFormalEducation List Dictionary
   */
  getHighestFormalEducationDictionary() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=education");
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get CanYouReadandWrite List Dictionary
   */
  getCanYouReadandWriteDictionary() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=can_read_write");
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get associatedHazard List Dictionary
   */
  getAssociatedHazardDictionary() {
    return this.http.get(
      this.baseUrl + "/get?dictionary_type=associated_hazard"
    );
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get major_tests List Dictionary
   */
  getmajorTestListDictionary() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=major_tests");
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get minorTests List Dictionary
   */
  getminorTestListDictionary() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=minor_tests");
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get sampleType List Dictionary
   */
  getsampleTypeDictionary() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=sample_types");
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get sampleType Unit Dictionary
   */
  getsampleTypeUnitDictionary() {
    return this.http.get(
      this.baseUrl + "/get?dictionary_type=sample_type_unit"
    );
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get Build Type List Dictionary
   */
  getbuildTypeDictionary() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=build_type");
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get dietary_supplement Dictionary
   */
  getDietarySupplementDictionary() {
    return this.http.get(
      this.baseUrl + "/get?dictionary_type=dietary_supplement"
    );
  }

  /**
   * This function is used to get all cities
   */
  getCityList() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=cities");
  }

  /**
   * This function is used to get all districts
   */
  getDistrictList() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=districts");
  }

  /**
   * This function is used to get all pincode
   */
  getPincodeList() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=pincodes");
  }

  /**
   * This function is used to get all medication name
   */
  getMedicationName() {
    return this.http.get(
      this.baseUrl + "/get?dictionary_type=drug_dictionaries"
    );
  }

  /**
   * This function is used to get all states
   */
  getStates() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=states");
  }

  /**
   * This function is used to get all medicine brand name
   */
  getMedicineBrandName() {
    return this.http.get(
      this.baseUrl + "/get?dictionary_type=medicine_brand_name"
    );
  }

  /* @author Pramod Kushwaha
   * This function is used to get Not Comply Diet Dictionary
   */
  getNotComplyDietDictionary() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=not_comply_diet");
  }

  /**
   * To get Frequency of Exposure Dictionary
   */
  getFrequencyOfExposureDictionary() {
    return this.http.get(
      this.baseUrl + "/get?dictionary_type=frequency_of_exposure"
    );
  }

  /**
   * To get Last Meal Dictionary
   */
  getLastMealDictionary() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=last_meal");
  }

  howLongYears: Observable<any>;
  howLongMonths: Observable<any>;
  howLongDays: Observable<any>;
  diseasesAlongWithDiabetes: Observable<any>;
  /**
   * This function is used to get given Dictionary
   */
  getDictionary(dictionaryName: string) {
    switch (dictionaryName) {
      case "how_long_years":
        if (this.howLongYears) {
          return this.howLongYears;
        }
        this.howLongYears = this.http
          .get(`${this.baseUrl}/get?dictionary_type=${dictionaryName}`)
          .pipe(share());

        return this.howLongYears;
        break;

      case "how_long_months":
        if (this.howLongMonths) {
          return this.howLongMonths;
        }
        this.howLongMonths = this.http
          .get(`${this.baseUrl}/get?dictionary_type=${dictionaryName}`)
          .pipe(share());

        return this.howLongMonths;
        break;

      case "how_long_days":
        if (this.howLongDays) {
          return this.howLongDays;
        }
        this.howLongDays = this.http
          .get(`${this.baseUrl}/get?dictionary_type=${dictionaryName}`)
          .pipe(share());

        return this.howLongDays;
        break;

      case "diseases_along_with_diabetes":
        if (this.diseasesAlongWithDiabetes) {
          return this.diseasesAlongWithDiabetes;
        }
        this.diseasesAlongWithDiabetes = this.http
          .get(`${this.baseUrl}/get?dictionary_type=${dictionaryName}`)
          .pipe(share());

        return this.diseasesAlongWithDiabetes;
        break;

      default:
        return this.http.get(
          `${this.baseUrl}/get?dictionary_type=${dictionaryName}`
        );
        break;
    }

    // return this.http.get(
    //   `${this.baseUrl}/get?dictionary_type=${dictionaryName}`
    // );
  }
}
