import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService, NbToastrService } from '@nebular/theme';
import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '../../../auth/service/auth.service';
import { Router, NavigationEnd, Data } from '@angular/router';
import Swal from 'sweetalert2';
import { AppConstants } from '../../../app.constant';
import { MenuService } from '../../../shared/services/menu.service';
import { UserService } from '../../../@core/mock/users.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  public Swal = require('sweetalert2');
  private destroy$: Subject<void> = new Subject<void>();
  public userPictureOnly: boolean = false;
  public user: any;
  public userPic: any = '../../../assets/images/' + AppConstants.defaultProfileImage;

  public themes = [
    {
      value: 'default',
      name: 'Light',
    },
  ];

  public currentTheme = 'default';
  public userMenu = [
    { title: 'Profile', icon: 'user', link: '/profile' },
    // { title: 'Setting', icon: 'user-cog' },
    { title: 'Log out', icon: 'unlock' },
  ];

  //             userMenu = [{ title: 'Profile', icon: 'user' },
  // { title: 'Setting', icon: 'user-cog' },
  // { title: 'Log out', icon: 'unlock' }];

  public items = [];
  public isHeaderNotificationLoading = false;
  private clickedItem: string;

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private authService: AuthService,
    protected router: Router,
    private breakpointService: NbMediaBreakpointsService,
    private coreMenuService: MenuService,
    private toastr: NbToastrService,
    private uService: UserService,
  ) { }

  ngOnInit() {

    this.menuService.onItemClick().subscribe((event) => {
      this.clickedItem = event.item.title;
      this.onItemSelection(event.item.title);
    });

    /* this.getAllQCNotification();

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) { this.getAllQCNotification(); }
    }); */

    this.currentTheme = this.themeService.currentTheme;

    // this.userService.getUsers()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((users: any) => this.user = JSON.parse(users));


    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    // console.log('item =');
    // console.log(userInfo);
    this.user = userInfo;

    this.uService.profileImageUpdate$.subscribe((profileImage) => {
      this.user.profile_pic = profileImage;

      // Update Local Storage
      const item = JSON.parse(localStorage.getItem('userInfo'));
      item['profile_pic'] = profileImage;
      localStorage.setItem('userInfo', JSON.stringify(item));
    });

    // update First Name
    this.uService.firstName$.subscribe((firstName) => {
      this.user.first_name = firstName;
      // Update Local Storage
      const item = JSON.parse(localStorage.getItem('userInfo'));
      item['first_name'] = firstName;
      localStorage.setItem('userInfo', JSON.stringify(item));
    });
    // update Last Name
    this.uService.lastName$.subscribe((lastName) => {
      this.user.last_name = lastName;
      // Update Local Storage
      const item = JSON.parse(localStorage.getItem('userInfo'));
      item['last_name'] = lastName;
      localStorage.setItem('userInfo', JSON.stringify(item));
    });

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  onItemSelection(title) {
    if (title === 'Log out') {
      // Do something on Log out
      this.logout();
      // // console.log('Log out Clicked ');
    } else if (title === 'Profile') {
      // Do something on Profile
      // // console.log('Profile Clicked ');
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    document.querySelector('.header-container').classList.toggle('collapse');
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  /**
   * Logout
   */
  logout() {
    Swal.fire({
      title: 'Logout?',
      text: 'Are you sure want to logout?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-secondary ml-1',
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {

        this.authService.logout();

        setTimeout(function () {
          this.router.navigate(['login']);
        }, 2000);
      }
      if (result.dismiss === Swal.DismissReason.cancel) { }
    });
  }

  public sendNotificationLoader = false;

  /**
   * Send Push Notification To Mobile
   */
  sendNotificationToMobile() {
    this.sendNotificationLoader = true;
    this.coreMenuService.sendPushNotificationToMobile().subscribe((result: Data) => {
      this.sendNotificationLoader = false;
      if (result.status === AppConstants.serverSuccessStatus) {
        this.toastr.success('Notification sent successfully', 'Success');
      } else {
        this.toastr.danger('Something went wrong. Please contact to administrator.', 'OOPS!');
      }
    }, err => {
      // // console.log('Notification err :: ', err);
    });
  }

  /**
   * Get all QC Notifications
   */
  /* getAllQCNotification() {
    if (this.router.url != '/test'){
      this.isHeaderNotificationLoading = true;
      this.coreMenuService.getHeaderNotification().subscribe(result => {
          this.items = result.data;

      }, err => {
        this.isHeaderNotificationLoading = false;
        // // console.log('Header Notification err :: ', err);
      },
      () => {
        this.isHeaderNotificationLoading = false;
      }
      );
    }
  } */
}
