import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { BlockCopyPasteDirective } from "./directives/block-copy-pate.directive";
import { ActionHeaderPermissionPipe } from "./pipes/action-header-permission.pipe";
import { NoBackFrontSpaceDirective } from "./directives/no-back-front-space.directive";
import { NoSpaceDirective } from "./directives/no-space.directive";
import { DisableControlDirective } from "./directives/disable-control.directive";
import { FieldErrorDisplayComponent } from "../pages/components/field-error-display/field-error-display.component";
import { NumberOnlyDirective } from "./directives/number-only.directive";

export const components = [
  BlockCopyPasteDirective,
  NoBackFrontSpaceDirective,
  NoSpaceDirective,
  DisableControlDirective,
  FieldErrorDisplayComponent,
  NumberOnlyDirective,
];

@NgModule({
  declarations: [...components, ActionHeaderPermissionPipe],
  imports: [CommonModule, RouterModule],
  exports: [...components],
  entryComponents: [],
})
export class SharedModule {}
