import { Injectable } from '@angular/core';
import { HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthService } from './auth.service';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {filter, map , catchError, take, finalize} from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from '../../app.constant';
import { switchMap } from 'rxjs/operators';
import { ToasterService } from 'angular2-toaster';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {
    rowData: any;
    errors: string[] = [];

    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(false);

    constructor(private authService: AuthService,
        protected router: Router,
        private activatedRoute: ActivatedRoute,
        protected tostr: ToasterService) {}


    refreshToken(): Observable<any> {
        return this.authService.refreshToken().pipe(map(res => {
            localStorage.setItem('userInfo', JSON.stringify(res.data));
            return true;
        }));

    }

    /**
     * Intercept where setting header token
     * @param req
     * @param next
     * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
     */

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = this.addTokenToRequest(req);
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === AppConstants.TokenExpiredStatus) {
                    if (this.refreshTokenInProgress) {
                        return this.refreshTokenSubject.pipe(
                            filter(result => result !== null),
                            take(1),
                            switchMap(() => next.handle(this.addTokenToRequest(req))),
                        );
                    } else {
                        this.refreshTokenInProgress = true;
                        this.refreshTokenSubject.next(null);
                        return this.refreshToken().pipe(
                            switchMap((success: boolean) => {
                                this.refreshTokenSubject.next(success);
                                req = this.addTokenToRequest(req);

                                return next.handle(req);
                            }),
                            finalize(() => this.refreshTokenInProgress = false),
                        );
                    }
                } else if (error.status === AppConstants.logoutStatus) {
                    localStorage.clear();
                    this.router.navigate(['login']);
                } else {
                    return throwError(error);
                }

            }));
    }

    /**
     *
     * @param req
     * @author Dhaval
     */
    addTokenToRequest(req: HttpRequest<any>) {
        const userId = localStorage.getItem('userId');
        const splitted = req.url.split('/', 3);
        if (splitted[2] !== 'globalgenecorp.atlassian.net') {
            req = req.clone({setHeaders: { 'x-login-source': AppConstants.loginSource }});
            if (userId) {
                const authToken = this.authService.getAuthorizationToken();
                req = req.clone({setHeaders: { token: `${authToken}`, 'x-login-source': AppConstants.loginSource }});
            }
        }
        return req;
    }
}


export class Data {
    data?: any;
    message?: any;
    status?: number;
    success?: number;
}
