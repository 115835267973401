import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[ngxNoBackFrontSpace]'
})
export class NoBackFrontSpaceDirective {

  constructor(private el: ElementRef) { }

  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    const value: string = this.el.nativeElement.value;
    if (!value.trim()) {
      this.el.nativeElement.value = value.trim();
    } else if (value && String(value).indexOf('  ') > 0) {
      this.el.nativeElement.value = value.split('  ').join(' ');
    }
    event.preventDefault();
  }
}
