import { Directive, ElementRef, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";
@Directive({
  selector: "[numberOnly]",
})
export class NumberOnlyDirective {
  private el: NgControl;

  constructor(private ngControl: NgControl, private elr: ElementRef) {
    this.el = ngControl;
  }

  // Listen for the input event to also handle copy and paste.
  @HostListener("input", ["$event.target.value"])
  onInput(value: string) {
    // console.log(this.elr.nativeElement.getAttribute('data-allowdecimal'))
    // Use NgControl patchValue to prevent the issue on validation
    if (this.elr.nativeElement.getAttribute("data-allowdecimal"))
      this.el.control.patchValue(value.replace(/[^0-9\.]/g, ""));
    else this.el.control.patchValue(value.replace(/[^0-9]/g, ""));
  }
}
