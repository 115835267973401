import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { NbSpinnerService } from '@nebular/theme';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss'],
})
export class ThankyouComponent implements OnInit {

  public displayMsg: string = null;
  public errorMsg: string = null;
  public inValidToken: boolean = false;
  public thankYou: boolean = false;

  /**
   *
   * @param route
   * @param router
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  constructor(
    private route: ActivatedRoute,
    protected router: Router,
    private spinner: NbSpinnerService,
    ) { }

  /**
   * @author Vijay Vyas <vijay.vyas@globalgenecorp.com>
   */
  ngOnInit() {
    this.spinner.load();
    const type = this.route.snapshot.paramMap.get('type');

    if (type === 'error') {
      this.errorMsg = localStorage.getItem('errorMsg');
      this.inValidToken = true;
    } else {
      this.displayMsg = localStorage.getItem('successMsg');
      this.thankYou = true;
    }

    if (this.errorMsg === null && this.displayMsg === null) {
      this.router.navigate(['/login']);
    }


  }



}
